import styled, { css } from "styled-components";
import banner from "assets/images/banner-about.jpg";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Banner = styled.figure`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const ImageBanner = styled.img.attrs({ src: banner })`
  width: 100%;
  max-width: max-content;
  object-fit: contain;
`;

export const TopTitle = styled.h5`
  font-size: 18px;
  color: ${colors.dark};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 65px;
  line-height: 65px;
  letter-spacing: -2px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 48px;

  @media screen and (max-width: 414px) {
    font-size: 48px;
    line-height: 48px;
  }
  @media screen and (max-width: 320px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Subtitle = styled.article`
  width: 100%;
  max-width: 500px;
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 80px;
`;

export const Navigation = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 2px solid #d5dce6;

  @media screen and (max-width: 414px) {
    flex-wrap: wrap;
  }
`;

interface INavigationButton {
  isActive?: boolean;
}
export const NavigationButton = styled.button.attrs({
  type: "button",
})<INavigationButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: ${fonts.GilroySemiBold};
  font-size: 18px;
  color: ${colors.grey};
  position: relative;
  transition: 300ms ease;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: ${colors.blue};
    position: absolute;
    bottom: -26px;
    z-index: 1;
    transition: 300ms ease;
  }

  &:hover {
    color: ${colors.blue};
    &:after {
      width: 100%;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.blue};
      &:after {
        width: 100%;
      }
    `}

  @media screen and (max-width: 768px) {
    font-size: 16px;
    min-height: 38px;
  }

  @media screen and (max-width: 414px) {
    font-size: 18px;
    min-height: auto;
    padding: 8px 0;
    &:after {
      display: none;
    }
  }
`;
