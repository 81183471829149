import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, fonts } from 'styles';

export const ImageWrapper = styled.figure`
  width: 100%;
  max-width: 340px;
  height: 340px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    /* width: 100%; */
    object-fit: cover;
  }
`;

export const Tag = styled.legend`
  display: block;
  padding: 4px 8px;
  background-color: ${colors.dark};
  font-family: ${fonts.GilroyBold};
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h5`
  font-family: ${fonts.GilroyBold};
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 24px;
`;

export const PostDate = styled.span`
  display: block;
  width: max-content;
  font-family: ${fonts.GilroyBold};
  font-size: 11px;
  text-transform: uppercase;
  color: #ffffff75;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    display: block;
    background-color: #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -16px;
    transition: 300ms ease;
    transform-origin: bottom left;
  }
`;

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;

  &:hover {
    ${PostDate} {
      &:after {
        transform: scaleX(1.5);
      }
    }
  }
`;
