import processosAdmGeral from 'assets/images/our-services/processos-adm-geral.png';
import registroMarcas from 'assets/images/our-services/registro-marcas.png';
import ecommerce from 'assets/images/our-services/ecommerce.png';
import lgpd from 'assets/images/our-services/lgpd.png';
import direitosAutorais from 'assets/images/our-services/direitos-autorais.png';
import redesSociais from 'assets/images/our-services/redes-sociais.png';

export interface IOurService {
  id: number;
  icon: string;
  title: string;
  description: string;
}

export const ourServices: IOurService[] = [
  {
    id: 1,
    icon: registroMarcas,
    title: 'Registro e Monitoramento de marcas',
    description: `- Consultoria para análise de viabilidade de registro de marca<br />
      - Pedido de registro de marca perante o INPI<br />
      - Defesas e recursos em pedido de registro de marca no INPI<br />
      - Defesas judiciais<br />
      - Notificação extrajudicial por uso indevido de marca`,
  },
  {
    id: 2,
    icon: ecommerce,
    title: 'Proteção de e-commerce',
    description: `- Consultoria jurídica para elaboração de e-commerce conforme a legislação vigente<br />
    - Registro de software<br />
    - Elaboração de termos de uso e contratos para site ou plataforma<br />
    - Defesa judicial contra ações de consumidores<br />
    - Acompanhamento em conciliação e mediação`,
  },
  {
    id: 3,
    icon: lgpd,
    title: 'Lei Geral de Proteção de Dados',
    description: `- Consultoria jurídica para adequação de empresas<br />
    - Elaboração de contratos e termos de privacidade<br />
    - Defesas judiciais<br />
    - DPO on demande`,
  },
  {
    id: 4,
    icon: redesSociais,
    title: 'Redes sociais',
    description: `- Consultoria jurídica e elaboração de contratos para influenciadores digitais<br />
    - Defesas judiciais nos casos de violação da reputação e ao direito de imagem<br />
    - Defesas contra casos de má prestação de serviços pela plataformas de redes sociais (Facebook, YouTube)`,
  },
  {
    id: 5,
    icon: direitosAutorais,
    title: 'Direitos autorais',
    description: `- Registro de direito autoral<br />
    - Notificação extrajudicial por violação de direito autoral<br />
    - Defesas judiciais`,
  },
  {
    id: 6,
    icon: processosAdmGeral,
    title: 'Processos administrativos em geral',
    description: `- Parcelamentos e defesas em cobranças de tributos<br />
    - Requerimentos administrativos<br />
    - Pedidos de informações<br />
    - Habeas data`,
  },
];
