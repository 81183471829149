import React, { useCallback, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Scaffold } from 'components/Shared';
import { FetchArticleActions, FetchArticleState } from 'store/ducks/articles';

import * as S from './styles';

interface ParamsProps {
  id: string;
}
const Post: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsProps>();

  const { data: article, loading } = useSelector<
    RootStateOrAny,
    FetchArticleState
  >((state) => state.fetchArticle);

  const getArticle = useCallback(() => {
    dispatch(FetchArticleActions.request(id));
  }, [dispatch, id]);

  useEffect(() => {
    getArticle();
  }, [getArticle]);

  useEffect(() => {
    return () => {
      dispatch(FetchArticleActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold>
      <S.Container>
        <S.Content>
          {loading && <S.Loading />}
          {article && (
            <>
              <S.ImageWrapper>
                {article.category && <S.Tag>{article.category.name}</S.Tag>}
                {article.image_url && (
                  <img src={article.image_url} alt={article.title} />
                )}
              </S.ImageWrapper>
              <S.Title>{article.title}</S.Title>
              <S.ContentExerpt
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
              <S.PostDate>
                {format(new Date(article.updated_at), "dd 'de' MMMM yyyy", {
                  locale: ptBR,
                })}
              </S.PostDate>
            </>
          )}
          <S.ButtonWrapper onClick={() => history.push('/blog')}>
            <S.Button>Voltar</S.Button>
          </S.ButtonWrapper>
        </S.Content>
      </S.Container>
    </Scaffold>
  );
};

export default Post;
