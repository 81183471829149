import React from 'react';
import { Scaffold } from 'components/Shared';
import TalktoUs from './TalktoUs';
import PaginateArticle from './PaginateArticles';
import * as S from './styles';

const Blog: React.FC = () => {
  return (
    <Scaffold>
      <S.Container>
        <S.Content>
          <S.Title>Blog</S.Title>
          <S.Text>
            Aqui você pode acompanhar todos os artigos escritos por nossos
            sócios. Não perca as novidades que podem ajudar o seu negócio!
          </S.Text>
          <PaginateArticle />
        </S.Content>
        <S.Aside>
          <TalktoUs />
        </S.Aside>
      </S.Container>
    </Scaffold>
  );
};

export default Blog;
