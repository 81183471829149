import styled from "styled-components";
import spotlightImageFirst from "assets/images/spotlight-first.jpg";
import spotlightImageLast from "assets/images/spotlight-last.jpg";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  padding: 88px 0;

  @media screen and (max-width: 500px) {
    padding: 48px 0;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const Title = styled.h1`
  font-family: ${fonts.GilroyLight};
  font-size: 60px;
  line-height: 60px;
  color: ${colors.dark};
  margin-bottom: 16px;

  span {
    font-family: ${fonts.GilroyBold};
  }

  @media screen and (max-width: 500px) {
    font-size: 48px;
    line-height: 48px;
  }

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }

  @media screen and (max-width: 375px) {
    font-size: 36px;
    line-height: 36px;
  }

  @media screen and (max-width: 320px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const Text = styled.article`
  width: 100%;
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark};
  margin-bottom: 54px;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  column-gap: 32px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    row-gap: 32px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
`;

export const SpotlightImageFirst = styled.img.attrs({
  src: spotlightImageFirst,
})`
  width: 100%;
  object-fit: contain;
`;

export const SpotlightImageLast = styled.img.attrs({
  src: spotlightImageLast,
})`
  object-fit: contain;
  width: 100%;
`;
