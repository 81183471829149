import React, { useState } from "react";
import { Scaffold, Contact } from "components/Shared";
import AboutUs from "./AboutUs";
import Professionals from "./Professionals";
import LGPD from "./LGPD";
import Intellectual from "./Intellectual";
import * as S from "./styles";

type TOptions = "aboutUs" | "professional" | "lgpd" | "intellectual";

type TNavigationOptions = {
  [label in TOptions]: JSX.Element;
};

const navigationOptions: TNavigationOptions = {
  aboutUs: <AboutUs />,
  professional: <Professionals />,
  lgpd: <LGPD />,
  intellectual: <Intellectual />,
};
const WhoWeAre: React.FC = () => {
  const [selected, setSelected] = useState<TOptions>("aboutUs");
  return (
    <Scaffold>
      <S.Container>
        <S.Banner>
          <S.ImageBanner />
        </S.Banner>
        <S.Content>
          <S.TopTitle>Quem somos</S.TopTitle>
          <S.Title>
            Direito digital
            <br />
            para tempos digitais
          </S.Title>
          <S.Subtitle>
            Somos um escritório de advocacia especializado em direito digital e
            propriedade intelectual. Ajudamos você a proteger suas criações e
            inventos como marcas, direitos autorais e softwares.
          </S.Subtitle>
          <S.Navigation>
            <S.NavigationButton
              isActive={selected === "aboutUs"}
              onClick={() => setSelected("aboutUs")}
            >
              Sobre nós
            </S.NavigationButton>
            <S.NavigationButton
              isActive={selected === "professional"}
              onClick={() => setSelected("professional")}
            >
              Profissionais
            </S.NavigationButton>
            <S.NavigationButton
              isActive={selected === "lgpd"}
              onClick={() => setSelected("lgpd")}
            >
              LGPD e Direito Digital
            </S.NavigationButton>
            <S.NavigationButton
              isActive={selected === "intellectual"}
              onClick={() => setSelected("intellectual")}
            >
              Propriedade Intelectual
            </S.NavigationButton>
          </S.Navigation>
        </S.Content>
        {navigationOptions[selected]}
        <S.Content>
          <Contact />
        </S.Content>
      </S.Container>
    </Scaffold>
  );
};

export default WhoWeAre;
