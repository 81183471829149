import React from 'react';

import * as S from './styles';

const Professionals: React.FC = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Content>
          <S.Livia />
        </S.Content>
        <S.Content className="info">
          <S.Name>Livia França Silva Leon</S.Name>
          <S.Text>
            <p>
              Cofundadora do escritório, é advogada especialista em direito
              público e com atuação voltada para propriedade intelectual e
              direito digital.
            </p>
            <p>
              Também é membro da Associação Internacional Law Talks e coautora
              das publicações "Propriedade Intelectual para empreendedores" e
              "Vamos entender melhor a LGPD?"
            </p>
            <p>
              Sua experiência profissional inclui ainda o cargo de Procuradora
              Legislativa na Câmara Municipal de Barra Mansa (até o momento) e o
              cargo de assessora jurídica do Ministério Público do Estado do Rio
              de Janeiro (período de 2010 a 2019).
            </p>
          </S.Text>
        </S.Content>
      </S.Wrapper>
      <S.Wrapper className="right">
        <S.Content className="info">
          <S.Name>Thiago Vinício Leon</S.Name>
          <S.Text>
            <p>
              Cofundador do escritório, é advogado especialista em direito
              público e tem ampla experiência profissional adquirida ao longo de
              anos como Procurador do Município de Volta Redonda, cargo que
              ocupa até o momento.
            </p>
            <p>
              Sua trajetória também inclui os cargos de Subprocurador-Geral do
              Município de Volta Redonda (período de 2016 a 2020) e de Assessor
              Jurídico do Ministério Público do Estado do Rio de Janeiro
              (período de 2010 a 2013).
            </p>
            <p>
              É ainda certificado pelo Banco Mundial pela participação no "2º
              Treinamento sobre Diretrizes de Aquisições e Seleções de
              Consultoria", realizado na cidade do Rio de Janeiro em setembro de
              2013.
            </p>
          </S.Text>
        </S.Content>
        <S.Content>
          <S.Thiago />
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default Professionals;
