/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";

const timing = (1 / 60) * 1000;
const decay = (v: number): number => {
  return -0.1 * ((1 / timing) ^ 4) + v;
};

export default function useHorizontalScroll<T extends HTMLElement>(
  scrollRef: React.RefObject<T>,
  enableHandling?: boolean
) {
  const [clickStartX, setClickStartX] = useState<number | undefined>();
  const [scrollStartX, setScrollStartX] = useState<number | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const [direction, setDirection] = useState(0);
  const [momentum, setMomentum] = useState(0);
  const [lastScrollX, setLastScrollX] = useState(0);
  const [speed, setSpeed] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLastScrollX = useCallback(
    throttle((screenX: number) => {
      setLastScrollX(screenX);
    }, timing),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMomentum = useCallback(
    throttle((newMomentum: number) => {
      setMomentum(newMomentum);
      if (scrollRef?.current) {
        const left =
          scrollRef.current.scrollLeft + newMomentum * timing * direction;
        scrollRef.current.scrollTo({
          left,
          behavior: "smooth",
        });
      }
    }),
    [direction, scrollRef]
  );

  const handleMouseMoveStart = useCallback(
    (e: MouseEvent) => {
      setClickStartX(e.screenX);
      setDirection(0);
      if (scrollRef.current) {
        setScrollStartX(scrollRef.current.scrollLeft);
      }
    },
    [scrollRef]
  );

  const handleMouseMoveEnd = useCallback((): void => {
    setClickStartX(undefined);
    setScrollStartX(undefined);
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      if (
        clickStartX !== undefined &&
        scrollStartX !== undefined &&
        scrollRef.current
      ) {
        const touchDelta = clickStartX - e.screenX;

        scrollRef.current.scrollLeft = scrollStartX + touchDelta;

        if (Math.abs(touchDelta) > 1) {
          setIsDragging(true);
          setDirection(touchDelta / Math.abs(touchDelta));
          setSpeed(Math.abs((lastScrollX - e.screenX) / timing));
          handleLastScrollX(e.screenX);
        }
      }
    },
    [clickStartX, handleLastScrollX, lastScrollX, scrollRef, scrollStartX]
  );

  const handleMoviment = useCallback((): void => {
    if (direction !== 0) {
      if (momentum > 0.1 && !isDragging) {
        handleMomentum(decay(momentum));
      } else if (isDragging) {
        setMomentum(speed);
      } else {
        setDirection(0);
      }
    }
  }, [direction, handleMomentum, isDragging, momentum, speed]);

  const handleScrollWhell = useCallback(
    (event): void => {
      scrollRef?.current?.scrollBy({
        left: event.deltaY,
        top: 0,
      });
    },
    [scrollRef]
  );

  useEffect(() => {
    handleMoviment();
  }, [handleMoviment]);

  useEffect(() => {
    if (
      scrollRef.current &&
      enableHandling &&
      scrollRef.current.ontouchstart === undefined
    ) {
      scrollRef.current.onmousedown = handleMouseMoveStart;
      scrollRef.current.onmousemove = handleMouseMove;
      scrollRef.current.onmouseup = handleMouseMoveEnd;
      scrollRef.current.onmouseleave = handleMouseMoveEnd;
    }
  }, [
    handleMouseMoveEnd,
    handleMouseMove,
    handleMouseMoveStart,
    scrollRef,
    enableHandling,
  ]);

  useEffect(() => {
    if (enableHandling) {
      scrollRef.current?.addEventListener("wheel", handleScrollWhell);
    } else {
      scrollRef.current?.removeEventListener("wheel", handleScrollWhell);
    }
    return () => {
      scrollRef.current?.removeEventListener("wheel", handleScrollWhell);
    };
  }, [handleScrollWhell, enableHandling]);

  return {
    clickStartX,
    scrollStartX,
    isDragging,
    direction,
    momentum,
    lastScrollX,
    speed,
  };
}
