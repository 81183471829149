import styled from "styled-components";
import { TitleH2 } from "styles/styled-components";
import { colors } from "styles";

export const Container = styled.section`
  width: 100%;
  padding-bottom: 96px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export const Title = styled(TitleH2)``;

export const Text = styled.article`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 64px;
`;
