import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  PaginateArticlesState,
  PaginateArticlesActions,
} from "store/ducks/articles";
import Card from "./Card";
import * as S from "./styles";

const LastPosts: React.FC = () => {
  const dispatch = useDispatch();
  const { data: articles, loading } = useSelector<
    RootStateOrAny,
    PaginateArticlesState
  >((state) => state.paginateArticles);

  const getLastArticles = useCallback(() => {
    dispatch(PaginateArticlesActions.request({ limit: 3 }));
  }, [dispatch]);

  useEffect(() => {
    getLastArticles();
  }, [getLastArticles]);

  useEffect(() => {
    return () => {
      dispatch(PaginateArticlesActions.reset());
    };
  }, [dispatch]);
  return (
    <S.Container>
      <S.Content>
        {loading && <S.ActivityIndicator />}
        {articles.map((article) => (
          <Card key={article.id} article={article} />
        ))}
      </S.Content>
    </S.Container>
  );
};

export default LastPosts;
