import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

const NavigationMobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();

  const handleNavigate = useCallback(
    (route: string) => {
      setIsOpen(false);
      history.push(route);
    },
    [history]
  );

  return (
    <S.Container>
      <S.ButtonMenu onClick={() => setIsOpen(true)}>
        <S.IconMenu />
      </S.ButtonMenu>
      <S.Navigation isOpen={isOpen}>
        <S.ButtonClose onClick={() => setIsOpen(false)}>
          <S.IconClose />
        </S.ButtonClose>
        <S.Option onClick={() => handleNavigate("/")}>Home</S.Option>
        <S.Option onClick={() => handleNavigate("/quem-somos")}>
          Quem somos
        </S.Option>
        <S.Option onClick={() => handleNavigate("/home/servicos")}>
          O que fazemos
        </S.Option>
        <S.Option onClick={() => handleNavigate("/blog")}>Blog</S.Option>
        <S.Option onClick={() => handleNavigate("/home/contato")}>
          Fale com a gente
        </S.Option>
      </S.Navigation>
    </S.Container>
  );
};

export default NavigationMobile;
