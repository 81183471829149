import styled from "styled-components";
import { colors } from "styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 32px;
`;

interface ButtonContract {
  active?: boolean;
}

export const Button = styled.button<ButtonContract>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? "#FFF" : colors.dark)};
  background-color: ${({ active }) => (active ? colors.dark : "#DFDFDF")};
  font-size: 14px;
  border-radius: 6px;
  transition: 300ms ease;
  :not(:last-child) {
    margin-right: 4px;
  }

  &:hover {
    background-color: ${colors.dark};
    color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
`;
