import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './ducks';
import sagas from './sagas';

const persistConfig = {
  key: 'FrancaLeon',
  whitelist: ['auth'],
  storage,
  version: 1,
};

const persistedRedurcer = persistReducer(persistConfig, reducers);

const middlewares = [];

// const sagaMonitor =
//   process.env.NODE_ENV === 'development'
//     ? console.tron.createSagaMonitor()
//     : null;

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const composer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares))
    : compose(applyMiddleware(...middlewares));

const store = createStore(persistedRedurcer, composer);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
