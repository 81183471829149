import styled, { css } from "styled-components";
import { MenuAltRight, X } from "@styled-icons/boxicons-regular";
import { colors, fonts } from "styles";

interface INavigation {
  isOpen: boolean;
}

export const Container = styled.section`
  display: none;

  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

export const ButtonMenu = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonClose = styled(ButtonMenu)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

export const IconMenu = styled(MenuAltRight).attrs({ size: 24 })`
  color: ${colors.dark};
`;
export const IconClose = styled(X).attrs({ size: 24 })`
  color: #fff;
`;

export const Navigation = styled.nav<INavigation>`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
  width: 100%;
  height: 100vh;
  padding: 48px 24px;
  background-color: ${colors.dark};
  position: fixed;
  top: 0;
  left: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
    `}
`;

export const Option = styled.button.attrs({ type: "button" })`
  font-family: ${fonts.GilroyBold};
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    bottom: -8px;
    transition: 300ms ease;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;
