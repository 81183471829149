import styled from 'styled-components';
import { fonts } from 'styles';

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  column-gap: 40px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 720px;
  padding: 40px 0;
`;

export const Aside = styled.aside`
  width: 100%;
  max-width: 263px;
  padding: 40px 0;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: ${fonts.GilroyBold};
  font-size: 82px;
  line-height: 84px;
  margin-bottom: 32px;
`;

export const Text = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  max-width: 500px;
  margin-bottom: 32px;
`;
