import styled from "styled-components";
import { colors } from "styles";

export const Container = styled.div`
  width: 310px;
  height: 400px;
  background-color: ${colors.lightGrey};
  border: 1px solid ${colors.lineGrey};
  padding: 48px 32px;

  img {
    margin-bottom: 24px;
  }
`;

export const Title = styled.h6`
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
`;

export const Description = styled.article`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.dark};
`;
