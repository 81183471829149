import React, { useRef } from "react";

import useHorizontalScroll from "./useHorizontalScroll";
import * as S from "./styles";

type Props = {
  children: JSX.Element[];
  snapAlign?: "start" | "center" | "end" | "none";
  enableHandling?: boolean;
};

export const HorizontalScrollable: React.FC<Props> = ({
  children,
  snapAlign = "start",
  enableHandling = false,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { isDragging } = useHorizontalScroll(scrollRef, enableHandling);

  return (
    <S.MainContainer>
      <S.ScrollableWrapper
        ref={scrollRef}
        style={{
          cursor: isDragging ? "grabbing" : "default",
          scrollSnapType: !isDragging ? "x mandatory" : undefined,
        }}
      >
        <S.ScrollableContainer>
          {children.map((child, i) => {
            return (
              <S.Item
                style={{
                  pointerEvents: isDragging ? "none" : undefined,
                  scrollSnapAlign: snapAlign,
                }}
                key={i.toString()}
              >
                {child}
              </S.Item>
            );
          })}
        </S.ScrollableContainer>
      </S.ScrollableWrapper>
    </S.MainContainer>
  );
};
