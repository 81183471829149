import React from 'react';

import * as S from './styles';

const TalktoUs: React.FC = () => {
  return (
    <S.Container>
      <S.IconWhats />
      <S.Title>Converse com a gente e tire suas dúvidas</S.Title>
      <S.Text>
        Quer saber mais sobre como podemos te ajudar a proteger a sua
        propriedade intelectual ou adequar o seu negócio às normas de proteção
        de dados? Vamos bater um papo!
      </S.Text>
      <S.Button onClick={() => window.open('https://wa.me/5524998318777')}>
        Fale com a gente!
      </S.Button>
    </S.Container>
  );
};

export default TalktoUs;
