import React from 'react';
import * as S from './styles';

const LGPD: React.FC = () => {
  return (
    <S.Container>
      <S.Title>LGPD e Direito Digital</S.Title>
      <S.Text>
        <p>
          Se a maioria das atividades da vida já são passíveis de transferência
          para o digital, não podemos ignorar as oportunidades e os riscos que
          isso traz. Mas você sabe como evitar problemas na migração do seu
          negócio para o digital? O que você deve considerar? E como agir quando
          o problema já está na sua porta?
        </p>
        <p>
          Para isso estamos aqui! A orientação adequada te ajudará a aproveitar
          as oportunidades do digital, a entender os riscos e como eliminá-los
          ou enfrentá-los. Pode contar com a gente!
        </p>
      </S.Text>
    </S.Container>
  );
};

export default LGPD;
