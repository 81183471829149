import styled from "styled-components";
import { colors } from "styles";

export const TitleH2 = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  line-height: 44px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 32px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: ${colors.dark};
    position: absolute;
    bottom: -16px;
  }

  @media screen and (max-width: 414px) {
    font-size: 32px;
    line-height: 32px;
  }

  @media screen and (max-width: 375px) {
    font-size: 28px;
    line-height: 28px;
  }

  @media screen and (max-width: 320px) {
    font-size: 22px;
    line-height: 22px;
  }
`;
