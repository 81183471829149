import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 88px 0;
`;

export const Title = styled.h2`
  font-family: ${fonts.GilroySemiBold};
  font-size: 40px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 40px;

  @media screen and (max-width: 414px) {
    font-size: 32px;
  }
  @media screen and (max-width: 375px) {
    font-size: 28px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 575px;
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
