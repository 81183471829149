import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const ScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; // IE
  /* overflow: -moz-scrollbars-none; // Firefox */
  scrollbar-width: none; // Firefox
  --webkit-overflow-scrolling: touch;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    display: none; // Chrome and Safari
  }
`;

export const ScrollableContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const Item = styled.div`
  width: fit-content;
  display: flex;
  flex: 1 0 auto;
`;
