import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos";
import { colors } from "styles";

export const Button = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: ${colors.dark};
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  transition: 300ms ease;

  @keyframes zoomIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: zoomIn 600ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Icon = styled(Whatsapp).attrs({ size: 24 })`
  color: #fff;
`;
