import styled, { css } from "styled-components";
import { colors } from "../colors";
import { fonts } from "../fonts";

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const InputCSS = css`
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  color: ${colors.dark};
  background-color: #fff;
  padding: 14px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  max-height: 50px;
  width: 100%;
`;

export const FieldLabel = styled.label`
  display: block;
  font-family: ${fonts.GilroySemiBold};
  font-size: 16px;
  color: ${colors.dark};
  margin-bottom: 12px;
`;

export const FieldError = styled.span`
  display: block;
  font-family: ${fonts.GilroySemiBold};
  font-size: 16px;
  color: ${colors.red};
  margin-top: 8px;
  margin-bottom: 12px;
`;

export const FormRow = styled.div.attrs({ className: "form-row" })`
  display: flex;
  flex-direction: row;
  .form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  button.button {
    flex: 1 0 auto;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const FormFooter = styled.div`
  margin-top: 32px;
`;
