import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, fonts } from "styles";

export const ImageWrapper = styled.figure`
  width: 100%;
  max-width: 720px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const Tag = styled.legend`
  display: block;
  padding: 4px 8px;
  background-color: ${colors.dark};
  font-family: ${fonts.GilroyBold};
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h5`
  font-family: ${fonts.GilroyBold};
  font-size: 36px;
  line-height: 42px;
  color: ${colors.dark};
  margin-bottom: 24px;
`;

export const ContentExerpt = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark};
  margin-bottom: 32px;
`;

export const PostDate = styled.span`
  display: block;
  width: max-content;
  font-family: ${fonts.GilroyBold};
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.dark};
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    display: block;
    background-color: ${colors.dark};
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -16px;
    transition: 300ms ease;
    transform-origin: bottom left;
  }
`;

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  padding-bottom: 56px;
  border-bottom: 1px solid ${colors.lineGrey};
  margin-bottom: 56px;

  &:hover {
    ${PostDate} {
      &:after {
        transform: scaleX(1.5);
      }
    }
  }
`;
