import React, { useState, useCallback, useRef } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "hooks";
import { api } from "../../../services/api";
import Swal from "sweetalert2";
import load from "./../../../assets/images/loading.gif";

import { Input, Select } from "components/Shared/Form";
import * as S from "./styles";

const periods = [
  { label: "Manhã", value: "Manhã" },
  { label: "Tarde", value: "Tarde" },
  { label: "Noite", value: "Noite" },
];

export const Contact: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required("Obrigatório"),
          email: Yup.string().required("Obrigatório"),
          phone: Yup.string().required("Obrigatório"),
          period: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, { abortEarly: false });

        const resp = await api.post("sendmail", data, {
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });

        if (resp.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Aviso",
            text: "Mensagem enviada com sucesso",
          });
          setLoading(false);
          return reset();
        }
        Swal.fire({
          icon: "error",
          title: "Aviso",
          text: "A Mensagem não foi enviada",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors]
  );
  return (
    <S.Container>
      <S.Content>
        <S.TextWrapper>
          <S.Title>Agende um bate-papo</S.Title>
          <S.Text>
            Entre em contato para saber como podemos colaborar com a proteção da
            sua marca, de suas criações e gerar valor para o seu negócio.
          </S.Text>
        </S.TextWrapper>
        <S.FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="name" label="Nome" />
            </S.FormRow>
            <S.FormRow>
              <Input name="email" label="E-mail" />
            </S.FormRow>
            <S.FormRow>
              <Input name="phone" label="Telefone" />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="period"
                label="Qual sua preferencia de horário?"
                options={periods}
              />
            </S.FormRow>
            <S.FormFooter>
              <S.Button>
                {loading === false ? (
                  "Quero Bater um Papo"
                ) : (
                  <img src={load} alt="loader" />
                )}
              </S.Button>
            </S.FormFooter>
          </Form>
        </S.FormContainer>
      </S.Content>
    </S.Container>
  );
};
